/**
 * @file Gp Landing.
 */

/**
 * Gp Landing functions.
 *
 * Runs js specific to the Gp Landing template.
 */
const vGpLanding = () => {
	// import('../../components/gp-header/gp-header').then(({ default: vGpHeader }) => { vGpHeader(); });
	import('../../components/gp-hero/gp-hero').then(({ default: vGpHero }) => { vGpHero(); });
};

if (document.readyState !== 'loading') {
	vGpLanding();
} else {
	document.addEventListener('DOMContentLoaded', vGpLanding);
}
